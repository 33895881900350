/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "El tabulador es una de esas teclas que en principio no le encuentras mucho sentido pero luego empiezas a darte cuenta de todos los usos que le puedes dar (Aunque por mas que lo intento aún no me a dado ningún refresco). El ultimo que le encontré me a sido de lo mas útil, cuando estoy escribiendo algo en la consola, por ejemplo un archivo con el nombre muy largo podemos empezar a escribir y cuando ya tengamos bastantes para que no se confunda con ningún otro archivo de la carpeta apretamos el Tab y se completará el archivo. También podemos usarlo con comandos. Descubrir esto a marcado un antes y un después en mi relación con linux ;) .");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
